@use '@typography' as *;

.link {
  display: flex;
  gap: 6px;
  align-items: center;
  color: var(--cst-hyperlink-tittle-default);
  min-width: max-content;
  cursor: pointer;
  @include typography-base;
  @include weight-medium;

  &:hover {
    color: var(--cst-hyperlink-title-hover);
  }
}
