@use '@fixed-size' as *;
@use '@queries' as *;

.faq-navigation {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, minmax(226px, 1fr));
  gap: 10px;

  > article {
    background: #fff;

    @include fixedSize(100%, 160px);

    @include min-1440-break {
      @include fixedSize(100%, 180px);
    }

    @include min-1920-break {
      @include fixedSize(100%, 200px);
    }
  }

  > * .active-card {
    outline: 3px solid #8767d1;
    outline-offset: -2.5px;

    &:hover {
      > div[id='card-category-bs'] {
        background: #f0ecf9;
      }
    }
    > div[id='card-category-bs'] {
      background: #f0ecf9;
    }

    .bottom-btn {
      color: var(--global-purple600);
    }
  }

  @include min-1440-break {
    gap: 20px;
    grid-template-columns: repeat(4, minmax(268px, 1fr));
  }

  @include min-1920-break {
    grid-template-columns: repeat(4, minmax(335px, 1fr));
  }
}
