@use '@queries' as *;

.questions {
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: max-content;
  min-height: 274px;
  gap: 10px;
  width: 100%;

  @include min-1440-break {
    min-height: 337px;
    gap: 17px;
  }

  @include min-1920-break {
    min-height: 456px;
    gap: 20px;
  }
}
