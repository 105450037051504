@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.popper {
  position: absolute;
  transform: translateY(1rem);
  opacity: 0;
  visibility: hidden;
  z-index: 20;
  transition: all 0.3s ease-in-out;

  &__opened {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }
}
