@use '@typography' as *;
@use '@queries' as *;

.link {
  @include typography-s;
  @include weight-medium;
  color: var(--cst-link-title-default);

  @include min-1440-break {
    @include typography-base;
  }
}
