@use '@queries' as *;
@use '@fixed-size' as *;
@use '@typography' as *;
.tabs,
.tabs > ul {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  gap: 12px;
  width: 100%;

  @include min-1440-break {
    gap: 14px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    .tab {
      all: unset;
      cursor: pointer;
      box-sizing: border-box;
      position: relative;
      @include fixedSize(46px, 47px);
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-items: center;

      @include min-1440-break {
        @include fixedSize(55px, 50px);
      }

      &:hover {
        .tab-text {
          color: var(--cst-tabitem-navigation-text-hover);
        }
      }

      .tab-text {
        transition: all 0.2s ease-in-out;
        color: var(--cst-tabitem-navigation-text-default);
        @include typography-xs;
        @include weight-medium;
        @include fixedSize(max-content, 15px);

        @include min-1440-break {
          @include typography-s;
          @include fixedSize(max-content, 18px);
        }

        &.active {
          color: var(--cst-tabitem-navigation-text-active);
        }
      }
    }
  }
}
