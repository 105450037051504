@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.footer {
  width: 100%;
  padding: 24px 30px;
  min-width: 1024px;
  overflow: hidden;
  height: max-content;
  box-shadow: var(--elevation-2);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include fixedSize(100%, 339px);

  @include min-1440-break {
    padding: 0 40px;
    @include fixedSize(100%, 340px);
  }

  @include min-1920-break {
    padding: 0 347px 0 151px;
  }

  .content-container {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 50px;

    @include min-1440-break {
      gap: 30px;
    }

    @include min-1920-break {
      gap: 100px;
    }
  }

  .left-slot {
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: flex-start;

    max-width: 360px;
    width: 100%;

    @include min-1440-break {
      max-width: 520px;
      gap: 24px;
    }

    .logo {
      cursor: pointer;
      @include fixedSize(112px, 27px);
    }

    .descr {
      color: var(--cst-footer-desripction);
      @include typography-xs;
      @include weight-regular;
    }

    .rights {
      color: var(--cst-footer-desripction);
      @include typography-s;
      @include weight-medium;
    }

    .social-icons {
      display: flex;
      align-items: center;
      gap: 8px;

      .social-icon {
        cursor: pointer;
        @include singleFixedSize(36px);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .right-slot {
    display: flex;
    gap: 16px;

    @include min-1440-break {
      gap: 18px;
    }

    .categories,
    .account,
    .support {
      display: flex;
      flex-direction: column;
      gap: 18px;
      align-items: flex-start;

      .title {
        color: var(--cst-footer-title);
        @include typography-base;
        @include weight-semi-bold;

        @include min-1440-break {
          @include typography-m;
        }
      }

      .links-container {
        display: flex;
        gap: 18px;
        justify-content: space-between;
        width: max-content;

        @include min-1440-break {
          gap: 24px;
        }

        .links {
          display: flex;
          flex-direction: column;
          gap: 18px;
          width: max-content;
        }
      }
    }

    .categories {
      min-width: 187px;

      @include min-1440-break {
        min-width: 244px;
      }

      @include min-1920-break {
        min-width: 278px;
      }
    }
    .account {
      min-width: 196px;

      @include min-1440-break {
        min-width: 244px;
      }

      @include min-1920-break {
        min-width: 278px;
      }
    }
    .support {
      min-width: 145px;
      @include min-1440-break {
        min-width: 198px;
      }
    }
  }
}

.footer-decoration {
  position: absolute;
  z-index: -1;
  pointer-events: none;
  right: -120px;
  rotate: 13deg;
  top: -5px;
  display: none;

  @include singleFixedSize(372.22px);

  @include min-1440-break {
    display: flex;

    @include singleFixedSize(300px);
    right: -133px;
    top: 34px;
  }

  @include min-1920-break {
    @include singleFixedSize(372.22px);
    top: 0;
    right: -125px;
    rotate: 12deg;
  }
}
