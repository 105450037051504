@use '@/app/queries' as *;
@use '@/shared/styles/fixed' as *;
@use '@/shared/ui/typography/sizes' as *;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--cornerradius10);
  cursor: pointer;
  &:hover {
    background-color: var(--cst-dropitem-background-hover);
    div > p {
      color: var(--cst-dropitem-text-hover);
    }
  }
  &:active {
    background-color: var(--cst-dropitem-background-pressed);
    div > p {
      color: var(--cst-dropitem-text-pressed);
    }
  }
  &-left-slot {
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      color: var(--cst-drop-listsort-text-default);
    }
    &-search {
      img {
        @include singleFixedSize(22px);
      }
      p {
        @include typography-s;
      }

      @include min-428-break {
        p {
          @include typography-base;
        }
      }

      @include min-1024-break {
        p {
          @include typography-xs;
        }
        img {
          @include singleFixedSize(18px);
        }
      }

      @include min-1440-break {
        p {
          @include typography-m;
        }
        img {
          @include singleFixedSize(22px);
        }
      }
    }

    &-game {
      @include min-1024-break {
        img {
          @include singleFixedSize(22px);
        }
      }
      @include min-1440-break {
        img {
          @include singleFixedSize(32px);
        }
      }
    }
  }
  &-drop_item {
    width: max-content;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.selected {
  background-color: var(--cst-dropitemgame-background-pressed) !important;
}

.check-icon {
  @include singleFixedSize(14px);
}

.close-icon {
  @include singleFixedSize(20px);
}
