@use '@fixed-size' as *;
@use '@queries' as *;
.category-button {
  @include singleFixedSize(44px);

  span:first-child {
    display: none;
  }

  @include min-1440-break {
    @include fixedSize(141px, 50px);
    span:first-child {
      display: block;
    }
  }
}
